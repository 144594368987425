@import '../_shared/imports';

.list__user {
  width: 100%;
  margin-right: -0.5em;
  margin-bottom: -0.5em;

  display: flex;
  flex-wrap: wrap;

  font-size: 0.667em;

  &-item {
    flex: 0 0 3em;
    width: 3em;
    height: 3em;
    margin-right: 0.5em;
    margin-bottom: 0.5em;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    color: $white;
    color: color-yiq($primary);
    background-color: $primary;
    background-color: var(--primary, $primary);
    border-radius: 1.5em;

    text-transform: uppercase;
    font-family: $headings-font-family;
    font-weight: bold;

    transition: all 0.2s ease;
  }

  &-modal {
    cursor: pointer;

    &:hover {
      .list__user-item {
        background-color: $secondary;
        background-color: var(--secondary, $secondary);
      }
    }
  }
}
