$white: var(--white, WHITE);
$primary: var(--primary, SIENNA);
$secondary: var(--secondary, ROSYBROWN);
$success: var(--success, #009933);
$info: var(--info, LIGHTSTEELBLUE);
$warning: var(--warning, LIGHTSALMON);
$danger: var(--danger, INDIANRED);

// $light: #f8f9fa;
$light: var(--light, #f3f4f5);
$darker: var(--darker, #cfd0d1);
$dark: var(--dark, #343a40);

$yiq-contrasted-threshold: 128;
$yiq-text-dark: $dark;
$yiq-text-light: $white;

$enable-rounded: false;

$link-hover-color: var(--secondary, $secondary);
$link-hover-decoration: none;

$paragraph-margin-bottom: 0;

$grid-breakpoints: (
  xs: 0,
  watch: 320px,
  phone: 480px,
  sm: 576px,
  phablet: 640px,
  tablet: 768px,
  md: 768.01px,
  netbook: 896px,
  lg: 992px,
  laptop: 1024px,
  desktop: 1200px,
  xl: 1200.01px,
  width: 1440px,
  full: 1600px,
  hd: 1920px,
  tv: 2560px,
  threek: 2880px,
  fourk: 3840px,
);

$headings-font-family: -apple-system, var(--headings-font, Raleway), BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
  Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-sans-serif: -apple-system, var(--default-font, Lora), BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
  Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-weight: 700;
$font-size-base: 1em;

$table-head-color: var(--primary, $primary);

$input-btn-border-width: 0;
$btn-border-width: 0;
$btn-font-family: $headings-font-family;
$btn-font-weight: bold;
// $input-font-family: $headings-font-family;
$input-bg: var(--light, $light);
$input-color: inherit;
$input-border-width: 0;
$input-border-color: #e9ecef;
$input-focus-border-color: var(--primary, $primary);
$input-focus-color: var(--primary, $primary);
$input-btn-focus-box-shadow: none;
$custom-file-color: white;
$custom-control-indicator-size: 1em;
$custom-control-gutter: 0.5em;
$custom-file-text: (
  nl: "Selecteren…",
  en: "Browse…",
);

$nav-link-padding-y: 0.5em;
$nav-link-padding-x: 1em;

$pagination-color: var(--primary, $primary);
$pagination-bg: var(--light, $light);
$pagination-border-width: 0;
$pagination-hover-color: white;
$pagination-hover-bg: var(--secondary, $secondary);
$pagination-active-color: white;
$pagination-active-bg: var(--primary, $primary);
$pagination-disabled-bg: var(--light, $light);

$modal-backdrop-bg: white;
$modal-backdrop-opacity: 0.85;
$modal-content-border-width: 0;

// $alert-padding-y:                   .75rem;
$alert-padding-x: 1.25em;
$alert-border-width: 0;

$carousel-indicator-width: 1rem;
$carousel-indicator-height: 1rem;
$carousel-indicator-hit-area-height: 1rem;
$carousel-indicator-spacer: 0.5rem;

$pageInfo: #757570;
