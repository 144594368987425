@import '../../_shared/imports';

.prevnext {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @include above('laptop') {
    position: fixed;
    position: sticky;
    position: -webkit-sticky;
    bottom: 1em;
  }

  .prevnext-link {
    position: relative;

    flex: 0 0 auto;
    padding: 0.5em 1em;

    color: $primary;
    background-color: $light;
    background-color: var(--light, $light);

    font-size: 1.75em;
    font-family: $headings-font-family;
    font-weight: bold;
    line-height: 1;

    transition: background-color 0.2s;

    &:hover,
    &:focus {
      color: white;
      background-color: $primary;
      background-color: var(--primary, $primary);
    }

    &__prev {
      margin: 0.5em 1em 0.5em 0;
      padding-left: 3em;

      text-align: right;

      @include below('laptop') {
        // order: 1;
      }

      svg {
        left: 1em;
        transition: left 0.3s ease;
      }

      &:hover,
      &:focus {
        svg {
          left: 0.5em;
        }
      }
    }

    &__next {
      max-width: 100%;
      margin-left: auto;
      padding: 1em;
      padding-right: 3em;

      order: 2;

      @include above('laptop') {
        max-width: calc(100% - 12.5rem);
      }

      @include below('laptop') {
        // order: 0;
      }

      svg {
        right: 1em;
        transition: right 0.3s ease;
      }

      &:hover,
      &:focus {
        svg {
          right: 0.5em;
        }
      }
    }

    &.disabled {
      pointer-events: none;
      color: $gray-300 !important;
    }

    > svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    > small {
      display: block;

      font-size: 0.7143em;
    }

    > * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
