@import '../_shared/imports';

.navtab {
  color: $primary;
  color: var(--primary, $primary);
  background-color: $light;
  background-color: var(--light, $light);
  border-radius: 0.25em;

  font-size: 1.25em;
  font-family: $headings-font-family;
  font-weight: bold;

  &-container {
    justify-content: flex-start;

    .navtab-item {
      padding: 1em 1.5em;

      &:first-child {
        border-top-left-radius: 0.25em;
        border-bottom-left-radius: 0.25em;
      }

      &[title] {
        position: relative;

        &:after {
          content: attr(title);
          position: absolute;
          top: 100%;
          left: -25%;
          right: -25%;

          margin-top: 0.25em;
          padding: 0.25em 0.5em;

          color: white;
          background-color: $primary;
          border-radius: 0.125em;

          font-weight: bold;
          text-align: center;
          font-size: 1rem;
        }
        &:before {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;

          margin-top: -0.1875em;
          margin-left: -0.5em;
          padding-left: 2px;

          border: 0.5em solid transparent;
          border-top-width: 0;
          border-bottom-color: $primary;
        }

        &:after,
        &:before {
          display: none;
          z-index: 1001;
        }

        &:hover,
        &:focus {
          &:after,
          &:before {
            display: block;
          }
        }
      }

      .icon {
        font-size: 1.25em;
        line-height: 1;
      }
    }
  }

  &-container__collapse {
    color: $primary;

    font-size: 1.2em;

    > .dropdown-toggle {
      flex: 0 0 100%;
      width: 100%;
      padding-left: 1.2em;
      padding-right: 0.6em;

      display: flex;
      align-items: center;

      color: inherit !important;
      outline: 0;
      box-shadow: none !important;

      .icon {
        margin-right: 1rem;
      }

      &:after {
        margin-left: 1rem;
      }
    }

    > .dropdown-menu {
      // width: 100%;
      // margin: 0 0 0 5px;
      margin-top: 3px;

      background-color: $light;
      border: 0;

      font-size: 0.8em;
      font-weight: normal;
    }

    .navtab-item {
      padding: 0;

      color: inherit;

      a {
        padding: 0.25em 1.5em;

        display: block;

        color: inherit;
      }

      &.dropdown-item {
        a[title]:after {
          content: attr(title);

          padding: 0 1em;

          font-size: 1rem;
          // white-space: normal;
        }
      }
    }
  }

  .navtab-item {
    transition: 0.2s ease;

    font-weight: bold;

    &:focus,
    &:hover {
      color: white;
      background-color: $secondary;
    }

    &.blocked,
    > a.blocked {
      color: $darker;
      cursor: default;
      &:focus,
      &:hover {
        background-color: $light;
      }
    }

    &.disabled,
    > a.disabled {
      color: $warning;
    }

    &.active,
    > a.active {
      color: white;
      background-color: $primary;

      cursor: default;
    }
  }

  &__summary {
    display: flex;

    .navtab-container__collapse {
      flex: 0 0 auto;
    }

    .navtab-container {
      .navtab-item {
        border-left: 0.1875em solid white;
      }
    }
  }
}
