html,
body {
  height: 100%;
  overflow: auto;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

html {
  color: $dark;
  color: color-yiq($white);
  background-color: $white;
}

body {
  color: $dark;
  color: color-yiq($white);
}
.faux-disable {
  pointer-events: none !important;
  background-color: grey !important;
}

.form-group {
  label {
    font-family: $headings-font-family;
  }

  input[type='date'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='file'],
  input[type='image'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  select,
  textarea,
  output {
    border-left-width: 0.25em;
  }

  ::selection {
    color: $white !important;
    background-color: $primary !important;
  }

  textarea {
    font-family: monospace;
    font-size: smaller !important;
    letter-spacing: -0.05em;
  }
}

table {
  width: 100%;
  margin: 1em 0;

  td,
  th {
    padding: 0.6em 0.5em 0.4em;
  }

  thead tr {
    border-bottom: 3px solid $light;
  }

  tbody tr {
    &:nth-child(even) {
      background-color: $light;
    }
  }
  .md-editor__preview & {
    font-size: smaller;
  }
}

.conditional {
  margin-top: 1em;
}

.btn:not(.dropdown-toggle) {
  letter-spacing: 0.0625em;
}

.pagination {
  font-family: $headings-font-family;
  justify-content: center;

  .page-item {
    margin: 0 0.25em;

    &.active {
      font-weight: bold;
    }
  }

  .page-link {
    min-width: 2.25rem;

    border-radius: 50%;

    text-align: center;

    transition: 0.2s;
  }
}

h1 {
  @include below('tablet') {
    font-size: 2em;
  }
  @include below('phablet') {
    font-size: 1.75em;
  }
}

h2 {
  @include below('tablet') {
    font-size: 1.75em;
  }

  @include below('tablet') {
    font-size: 1.5em;
  }
}

h3 {
  @include below('tablet') {
    font-size: 1.5em;
  }

  @include below('phablet') {
    font-size: 1.25em;
  }
}

h4 {
  @include below('tablet') {
    font-size: 1.25em;
  }

  @include below('phablet') {
    font-size: 1.125em;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1em;
}

* + h1 {
  margin-top: 4em;
}

* + h2 {
  margin-top: 2em;
}

* + h3 {
  margin-top: 1.5em;
}

* + h4 {
  margin-top: 1.25em;
}

* + h5 {
  margin-top: 1em;
}

* + h6 {
  margin-top: 0.5em;
}

p {
  + p {
    margin-top: 1em;
  }
}

hr {
  height: 3px;
  width: 100%;

  background-color: $light;
  background-color: var(--light, $light);
  border: 0;
}

img {
  max-width: 100%;
  display: block;
}

#pageContent {
  position: relative;
  z-index: 4;

  .micro-image {
    min-width: 15%;
    max-width: 20%;
    margin: 0 auto;
  }
  .tiny-image {
    min-width: 25%;
    max-width: 40%;
    margin: 0 auto;
  }
  .small-image {
    min-width: 60%;
    max-width: 75%;
    margin: 0 auto;
  }
  .medium-image {
    min-width: 100%;
    max-width: 120%;
    margin: 0 auto;
  }
  .large-image {
    min-width: 150%;
    max-width: 220%;
    margin-left: -28%;
    overflow: hidden;
  }
  .banner-image {
    min-width: 230%;
    max-width: 250%;
    max-height: 20em;
    margin-left: -25em;
    object-fit: cover;
    overflow: hidden;
  }
}

.pageInfo {
  color: $pageInfo;
  padding-top: 0;

  span {
    width: 100%;
    display: inline;
  }
}
.pageInfo__type {
  color: $pageInfo;
  margin-right: 1em;
}
.titleOverride {
  margin-bottom: 0;
}

blockquote {
  margin: 1em 0;
  padding: 0.5em 0.75em;
  background-color: $light;
  border-left: 0.5em solid $primary;
  border-radius: 0.2em;
  font-style: italic;
}

.courseview,
.sectionview {
  section .width.width__vertical {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  h2 {
    margin-bottom: 0.5rem;
  }

  .sectionlist {
    svg {
      margin-right: 2rem;
    }
    small .disabled svg {
      color: $darker;
    }
    .disabled {
      color: $darker;
    }
  }
}

.sectionview {
  h4 {
    margin-top: 1rem;
  }
  .main-classroom-link,
  .secondary-classroom-link {
    padding: 1rem;
    border-radius: 0.6em;
    &:hover {
      text-decoration: underline;
    }
  }
  .main-classroom-link {
    background-color: $primary;
    color: $white;
    min-width: 16.5rem;
  }
  .secondary-classroom-link {
    background-color: $light;
    color: $dark;
    min-width: 8rem;
    border: 2px solid $primary;
  }
}