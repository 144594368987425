@import '../_shared/imports';

#footer {
  margin-top: 4rem;
  margin-bottom: -2rem;

  color: $dark;
  color: var(--dark, $dark);
  background-color: $light;
  background-color: var(--light, $light);

  font-family: $headings-font-family;

  [class*='col'] {
    // margin-bottom: 2rem;
  }
}
