@import '../../_layout/_shared/imports';

#main {
  .form-group {
    display: flex;
    flex-wrap: wrap;

    > label {
      flex-basis: 100%;
      color: $primary;
    }

    .custom-control {
      position: relative;

      &.custom-radio,
      &.custom-checkbox {
        flex: 1 0 auto;
        max-width: 100%;
        padding: 0.25em;

        display: flex;

        @include above('phablet') {
          max-width: 50%;
        }

        @include above('tablet') {
          // max-width: 33.333%;
        }

        @include above('laptop') {
          // max-width: 25%; // Layout fix: text was cut off on longer words
        }

        > label {
          position: static;

          flex: 0 0 100%;
          padding: 0.3125em 0.8125em;
          // padding-right: 1.75em;

          background-color: $light;
          border-radius: 0.25em;
          border: 0.1875em solid transparent;

          text-indent: 1.5em;

          &:before,
          &:after {
            top: 0.5em;
            left: 0.5em;

            background-color: none;
            background-size: 80%;
            border-radius: 0.125em;

            font-size: 1.5em;
          }
        }
        input {
          + label:before {
            background-color: transparent;
            // background-color: $white;
          }
        }
        input:checked {
          + label {
            color: white;
            background-color: $primary;
            // border-color: $primary;
          }
        }
      }
    }
  }

  .lefappsForms {
    /* Tangential@lefapps/forms-pages */
    aside.tangential {
      position: relative;
      left: 2.5vw;

      margin-top: 2em;
      margin-bottom: 2em;
      padding-left: 60%;

      &:before {
        content: '';

        position: absolute;
        top: 50%;
        left: 2em;
        right: 40%;

        height: 3px;
        margin-top: -2px;

        background: repeating-linear-gradient(to right, $primary 0%, $primary 50%, transparent 50%, transparent 100%)
          repeat-x center center;
        background-size: 20px 100%;
      }

      &:after {
        content: attr(data-marker);

        position: absolute;
        top: 50%;
        left: 0;

        width: 1.6em;
        height: 1.6em;
        margin-top: -0.8em;

        color: $primary;
        background-color: $light;
        border: 3px solid $primary;
        border-radius: 0.8em;

        font-size: 2.5em;
        text-align: center;
        font-weight: bold;
      }

      > .content {
        padding: 1em;

        color: $white;
        background-color: $primary;
        border-radius: 0.25em;

        a {
          color: inherit;
          text-decoration: underline;

          font-weight: bold;
        }
      }
    }
  }
}
