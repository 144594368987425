$amplifyNav: (
  greetingsNavBar: '.Nav__navBar___xtCFA',
  greeetingsNav: '.Nav__nav___2Dx2Y',
  username: '.Nav__navItem___1LtFQ',
  signOutButton: '.Button__button___vS7Mv'
);

#nav {
  #{map-get($amplifyNav, greetingsNavBar)} {
    border: 0;
  }

  #{map-get($amplifyNav, greeetingsNav)} {
    padding: 0;

    #{map-get($amplifyNav, username)},
    #{map-get($amplifyNav, signOutButton)} {
      padding: 0.5em;

      display: block;
    }

    #{map-get($amplifyNav, username)} {
      font-size: 1rem;
    }

    #{map-get($amplifyNav, signOutButton)} {
      background-color: $dark;
      background-color: var(--dark, $dark);
    }
  }
}
