@import '../../_layout/_shared/imports';

#admin-dashboard,
.admin-dashboard__style {
  .form-group {
    > label {
      flex-basis: 100%;
      color: $primary;
    }

    .custom-control.custom-switch {
      margin-top: -2.5em;

      padding-left: 0;
      padding-right: 2.25em;

      .custom-control-label {
        position: relative;
        width: 100%;

        &:before {
          left: auto;
          right: -2.25em;
        }

        &:after {
          left: auto;
          right: -1.5em;
        }
      }
    }
  }
}
