@import '../_shared/imports';

.heading {
  color: $primary;

  text-align: center;

  .lead {
    text-align: center;

    &:not(::first-child) {
      margin-top: 2em;
    }
  }
}
