@import 'amplifyAuth';

#loginPageContainer,
#loginMenuContainer {
  button {
    color: $light !important;
    color: var(--light, $light) !important;
    background-color: $primary !important;
    background-color: var(--primary, $primary) !important;
  }
}

#loginMenuContainer {
  div {
    border: none;
  }
  [data-test='greetings-nav-right'] div {
    display: none;
  }
}
