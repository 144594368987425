@import 'imports';

// import bootstrap modules manually to override some functions
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

@import 'overrides';
@import 'login';
@import 'alert';

#root {
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  > * {
    flex: 0 0 auto;
  }

  > #main {
    flex: 1 0 auto;
  }
}

// Styling for zoom call
.iframeContainer iframe {
  min-width: 100%;
  min-height: 50rem;
}
// overrides for the zoom call that likes to ruin everything
html, body {
  min-width: 100% !important;
}
body {
  overflow: auto !important;
}

body > #zmmtg-root {
  display: none;
}

// Styling for jitsi call
#jitsi-container {
  margin-left: 1em;

  display: 'block';
  width: '100%';
  height: '100%';
}

// Overwrite for translated content in adminlist
.admin-board__list-item__content {
  .translation {
    padding-left: 1.5em;
    font-size: 0.9em;
    white-space: nowrap;
    overflow: hidden;
  }
  
  small {
    padding-left: 2.5em;
    color: $primary !important;
  }
}

.contentTranslationSwitcher {
  max-width: 10em;
  margin-bottom: -10em;

  position: absolute;
  right: 5em;

  .dropdown-menu {
    margin-left: -15em;
  }
}

#language-picker {
  list-style: none;
  padding-left: 2em;
}