@import '../../_shared/imports';

#progress {
  height: 7px;

  background-color: white;
}

#levelUp {
  margin: 0.25em;
  margin-bottom: -2em;
  padding: 0.25em;

  width: 1.5em;
  height: 1.5em;

  display: inline-block;

  border-radius: 50%;

  text-align: center;
  font-size: 1.5em;
  line-height: 1;

  &:hover,
  &:focus {
    color: $white;
    background-color: $primary;
  }
}
