@import '../_shared/imports';

.infobox {
  background-color: $light;
  background-color: var(--light, $light);
  border-radius: 0.25em;

  ol {
    margin-top: 1em;
  }

  &__head {
    margin: 0;
    padding: 0.5em 1em !important;

    color: white;

    font-size: 1em;
    font-weight: bold;

    svg {
      margin-right: 1em;
    }

    &:empty {
      display: none;
    }
  }

  &__body {
    padding: 1em;
  }

  h4 {
    padding-top: 1rem;
  }
}

.toggle-box {
  cursor: pointer;
}
