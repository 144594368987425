@import '../../_layout/_shared/imports';

.lefappsForms-editor__insert {
  position: relative;

  width: 100%;
  padding: 0.25em 0;

  display: flex !important;
  justify-content: center;

  transition: 0.3s ease;

  &:before {
    content: '';
    position: absolute;
    left: 0.25em;
    top: 50%;
    right: 0.25em;

    height: 0;
    margin-top: -2px;

    border-top: 3px dashed $dark;
    border-top: 3px dashed var(--dark, $dark);
  }

  &:not(:first-of-type):not(:last-of-type):not(:hover):not(:focus) {
    margin-top: -1em;
    margin-bottom: -1em;

    & > .btn {
      opacity: 0;
      pointer-events: none;
    }
  }

  > .btn {
    flex: 0 1 auto !important;
  }
}

.lefappsForms-editor__container {
  > .card {
    position: relative;
    z-index: 5;
  }
}

.lefappsForms-editor {
  position: relative;

  > .row {
    > [class*='my-'] {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  > .lefappsForms-editor__buttons {
    position: sticky;
    bottom: 0;

    margin: -1em;
    padding: 1em;

    justify-content: space-between;
    align-items: baseline;

    background-color: rgba(white, 0.8);
    -webkit-backdrop-filter: blur(0.2em);
    backdrop-filter: blur(0.2em);
  }
}
