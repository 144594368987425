@import '../../_layout/_shared/imports';

.breadcrumbs {
  display: flex;
  justify-content: center;

  font-size: 0.875em;
  font-family: $headings-font-family;
  text-transform: lowercase;

  .breadcrumb {
    position: relative;

    max-width: 100%;

    justify-content: flex-end;
    flex-wrap: nowrap;

    background-color: $light;

    white-space: nowrap;

    &:before,
    &:after {
      content: '';

      position: absolute;
      top: 0;
      bottom: 0;

      border: 0.5em solid $light;
      border-top-width: 1.5em;
      border-bottom-width: 1.5em;
    }

    &:before {
      right: 100%;
      border-right-width: 0;
      border-left-color: transparent;
    }

    &:after {
      left: 100%;
      border-left-width: 0;
      border-right-color: transparent;
    }

    .breadcrumb-item {
      + .breadcrumb-item {
        &:before {
          content: '→';
        }
      }
    }
  }
}
