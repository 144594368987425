@import '../_shared/imports';

.list__simple {
  padding: 0;

  background-color: $light;
  background-color: var(--light, $light);
  border: 0 solid $gray-200;
  border-top-width: 0.25em;
  border-bottom-width: 0.25em;

  list-style: none;

  .linked {
    font-style: italic;
  }

  &-item {
    display: flex;
    align-items: center;

    & + & {
      border-top: 0.25em solid $gray-200;
    }

    &:before {
      content: attr(data-label);

      flex: 0 0 4rem;
      padding: 1rem;

      align-self: center;

      color: $primary;
      color: var(--primary, $primary);

      line-height: 1;
      font-family: $headings-font-family;
      text-align: center;
      text-transform: capitalize;

      @include above('phone') {
        flex-basis: 6rem;
        font-size: 1.5em;
      }
    }

    &__content,
    &__footer {
      padding: 1rem;
    }

    &__content {
      min-width: 20em;
    }

    &__footer {
      margin-left: auto;

      @include below('phone') {
        max-width: 4rem;

        font-size: 0.75em;
      }
    }
  }
}
