$amplifyLogin: (
  sectionContainer: '.Section__container___3YYTG',
  formContainer: '.Form__formContainer___1GA3x',
  formWrapper: '.Form__formSection___1PPvW',
  formHeader: '.Section__sectionHeaderContent___1UCqa',
  formBody: '.Section__sectionBody___ihqqd',
  formGroup: '.Form__formField___38Ikl',
  formLabel: '.Input__inputLabel___3VF0S',
  formInput: '.Input__input___3e_bf',
  formSelect: '.SelectInput__selectInput___3efO4',
  formHint: '.Hint__hint___2XngB',
  formFooter: '.Section__sectionFooter___1T54C',
  formFooterPrimary: '.Section__sectionFooterPrimaryContent___2r9ZX',
  formFooterSecondary: '.Section__sectionFooterSecondaryContent___Nj41Q',
  formAlert: '.Toast__toast___XXr3v',
  formAlertClose: '.Toast__toastClose___18lU4'
);

#{map-get($amplifyLogin, formContainer)} {
  margin: 0;
}

#{map-get($amplifyLogin, formWrapper)} {
  min-width: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  border: 0;
  box-shadow: none;

  font-family: $headings-font-family;
  font-size: 1rem;
}

#{map-get($amplifyLogin, formHeader)} {
  font-weight: bold;
  font-size: 1.5rem;
}

#{map-get($amplifyLogin, formBody)} {
  margin-bottom: 0;

  #{map-get($amplifyLogin, formGroup)} {
    margin-bottom: 1em;

    #{map-get($amplifyLogin, formLabel)} {
      font-size: 1em;
    }

    #{map-get($amplifyLogin, formSelect)} {
      [name='dial_code'] {
        flex-basis: 25%;
        min-width: 64px;
      }
    }

    #{map-get($amplifyLogin, formSelect)} select,
    #{map-get($amplifyLogin, formInput)} {
      padding: 0.5em 1em;

      background-color: $light;
      background-color: var(--light, $light);
      border: 0;
      border-left: 0.25em solid #e9ecef;
      border-radius: 0;

      font-size: 1em;
      font-family: $font-family-sans-serif;

      outline: 0;

      &:focus {
        color: $primary;
        color: var(--primary, $primary);
        border-color: $primary;
        border-color: var(--primary, $primary);
      }
    }
  }

  #{map-get($amplifyLogin, formHint)} {
    font-size: 0.875em;
  }
}

#{map-get($amplifyLogin, formFooter)} {
  display: block;
  font-size: 1em;

  span {
    display: block;

    margin-bottom: 1.5em;
  }

  button {
    padding: 0.5em;

    color: white;
    background-color: $secondary;
    background-color: var(--secondary, $secondary);

    font-size: 1rem;
  }

  #{map-get($amplifyLogin, formFooterSecondary)} {
    font-size: 0.875em;
  }
}

#{map-get($amplifyLogin, sectionContainer)} {
  position: relative;

  #{map-get($amplifyLogin, formAlert)} {
    position: sticky;

    width: 100%;
    margin-bottom: 1em;
    padding: 0.5em;

    color: $warning;
    color: var(--warning);
    background-color: $light;
    background-color: var(--light);
    box-shadow: none;

    #{map-get($amplifyLogin, formAlertClose)} {
      &:before,
      &:after {
        background-color: $warning !important;
        background-color: var(--warning) !important;
      }
    }
  }
}

#{map-get($amplifyLogin, formFooterSecondary)}-like {
  color: var(--grey, silver);

  font-size: 0.875em;
  font-family: $headings-font-family;

  cursor: pointer;
}
