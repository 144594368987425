@import '../_shared/imports';

.list__block {
  display: flex;
  flex-wrap: wrap;

  & &-item {
    flex: 0 0 100%;
    margin: 0 0 1em;

    overflow: hidden;

    color: $dark;
    color: color-yiq($light);
    background-color: $light;
    background-color: var(--light, $light);
    border: 0;
    border-radius: 0.25em;

    transition: background-color 0.2s;

    @each $colorName, $colorVal in $theme-colors {
      &__#{$colorName} {
        .card-header {
          color: $white;
          color: color-yiq($colorVal);
          background-color: $colorVal;
        }
      }
    }

    &[href] {
      &:hover,
      &:focus {
        background-color: $gray-200;
      }
    }

    .card-header,
    .card-footer,
    .card-body {
      border: 0;
      background-position: right center;
      background-size: contain;
      background-repeat: no-repeat;

      &:empty {
        display: none;
      }

      > :last-of-type {
        margin-bottom: 0 !important;
      }
    }

    .card-header,
    .card-footer {
      padding: 1em;

      align-self: stretch;

      &:empty {
        display: none;
      }

      &[style*='background-image'] {
        @include below('phablet') {
          background-image: none !important;
        }
        @include above('phablet') {
          padding-right: 13em;

          .grid__aside & {
            padding-right: 1em;
            background-size: cover;
          }
        }

        .grid.grid-collapse.grid-collapse__phablet &,
        .grid.grid-collapse.grid-collapse__tablet &,
        .grid.grid-collapse.grid-collapse__netbook & {
          @include below('netbook') {
            padding-right: 1em;
            background-image: none !important;
          }
          @include above('netbook') {
            padding-right: 13em;
          }
        }
      }

      small {
        font-size: 0.875em;
      }

      a {
        color: inherit;
      }
    }

    .card-body {
      flex: 1 0 0;
      padding: 1.1em 1em 0.9em;

      &[style*='background-image'] {
        @include below('phablet') {
          padding-right: 13em;
        }
        @include above('phablet') {
          background-image: none !important;
        }
      }
    }
  }

  &__nested {
    padding-left: 2rem;

    font-size: 0.75rem;

    .card-body {
      display: none;
    }

    .list__block-item {
      margin-bottom: 0.25rem !important;
    }
  }

  &__small {
    // margin: -0.5rem 0;

    font-size: 0.75em;

    .list__block-item {
      margin: 0.25em 0;
    }
  }
}
