@import '../_shared/imports';

.grid {
  display: flex;

  .grid__main,
  .grid__aside {
    background-color: $white;

    > img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &:not(.grid-collapse) {
    .grid__main,
    .grid__aside {
      display: flex;
      flex-wrap: wrap;

      > :first-child {
        margin-top: 0;
        padding-top: 0;
      }

      > :last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .grid__main {
      flex: 2 0 50%;
      width: 50%;

      & + .grid__aside {
        border-left: 0.25rem solid $light;
        border-left: 0.25rem solid var(--light, $light);
      }
    }

    .grid__aside {
      flex: 1 0 20rem;
      width: 20rem;

      & + .grid__main {
        border-left: 0.25rem solid $light;
        border-left: 0.25rem solid var(--light, $light);
      }
    }
  }

  &.grid-collapse {
    .grid__aside,
    .grid__main {
      align-content: flex-start;
    }
  }

  @each $widthName, $width in $grid-breakpoints {
    &.grid-collapse__#{$widthName} {
      @include below($widthName) {
        flex-wrap: wrap;

        .grid__aside,
        .grid__main {
          display: contents !important;
        }

        @for $order from 0 to 12 {
          .grid-order__#{$order} {
            order: $order;
          }
        }

        .grid-collapsed__hide {
          display: none !important;
        }
      }

      @include above($widthName) {
        .grid__main,
        .grid__aside {
          display: flex;
          flex-wrap: wrap;

          > :first-child {
            margin-top: 0;
            padding-top: 0;
          }

          > :last-child {
            margin-bottom: 0;
            padding-bottom: 0;
          }
        }

        .grid__main {
          flex: 2 0 50%;
          width: 50%;

          & + .grid__aside {
            border-left: 0.25rem solid $light;
            border-left: 0.25rem solid var(--light, $light);
          }
        }

        .grid__aside {
          flex: 1 0 20rem;
          width: 20rem;

          & + .grid__main {
            border-left: 0.25rem solid $light;
            border-left: 0.25rem solid var(--light, $light);
          }
        }

        .grid-collapsed__show {
          display: none !important;
        }
      }
    }
  }
}
