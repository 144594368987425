@import '../_shared/imports';

p.lead {
  text-align: center;

  &__dropCap {
    text-align: left;

    &:first-letter {
      color: $primary;
      color: var(--primary, $primary);

      initial-letter: 2;
    }
  }
}
