@import '../../_layout/_shared/imports';

$super-success: $info;

.return__icon:hover {
  cursor: pointer;
}

.moduleResult {
  .pageLink:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &__giveaway {
    .lefappsForms-results {
      .lefappsForms-results__answer {
        &[data-score] {
          &:after {
            margin-left: 1em;
            display: inline-block;
          }
        }
        &[data-score='0'] {
          &[data-checked='true'] {
            &:after {
              content: '✕';
              color: $danger;
            }
            background-color: rgba($danger, 0.2) !important;
            text-decoration: line-through;
          }
        }
        &[data-score='1'],
        &[data-score='2'] {
          &:after {
            color: $success;
            content: '✓';
          }
          background-color: rgba($success, 0.2) !important;
        }
        &[data-score='2'] {
          &:after {
            content: '✓✓';
          }
        }
      }
    }
  }

  .lefappsForms-results {
    ul,
    ol {
      flex: 0 0 100%;
      padding-left: 0;
    }

    label {
      color: $primary;
      font-weight: bold;
    }

    .lefappsForms-results__answer-subform {
      margin-top: 1em;
      padding-top: 0;

      border-left: 0.25em solid $light;

      list-style: none;
    }

    .lefappsForms-results__answer {
      margin-top: 0.125em;
      margin-bottom: 0.125em;
      padding-left: 0.5em;

      border-radius: 0.75em;

      list-style-position: inside;

      &[data-checked='true'] {
        background-color: $light;

        font-weight: bold;

        &:before {
          content: '← jouw antwoord';

          min-width: 1.4em;
          max-height: 1.4em;
          margin: 0.25em;
          padding: 0 0.75em;

          display: block;
          float: right;

          color: white;
          background-color: $info;
          border-radius: 0.7em;

          text-align: center;
          font-weight: bold;
          font-size: 0.8em;
          line-height: 1.4;
        }
      }
    }
  }
}

.lefappsFormsShow__prompt {
  position: relative;
  top: -1em;

  padding: 0 0.25em;

  text-align: right;

  .btn {
    border-radius: $border-radius;
  }

  p {
    padding: 0.4em 1em 0.6em;

    // color: $info;
    // background-color: rgba($info, 0.2);
    // border-radius: $border-radius;

    text-align: left;

    // font-size: small;

    &:empty {
      display: none;
    }
  }
}

.lefappsFormsShow .lefappsForms {
  $scores: ('0', '0.5', '1', '2');
  $score-colors: (
    '0': $danger,
    '0.5': $warning,
    '1': $success,
    '2': $super-success,
  );
  $score-decoration: (
    '0': line-through $danger,
    '0.5': underline wavy $warning,
    '1': underline $success,
    '2': underline double $super-success,
  );
  $score-icons: (
    // frown
    '0':
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm2.58 5.42a1.031 1.031 0 110 2.064 1.031 1.031 0 110-2.065zm-5.16 0a1.031 1.031 0 110 2.064 1.031 1.031 0 110-2.065zm5.49 7.038A3.785 3.785 0 008 11.097a3.785 3.785 0 00-2.91 1.361c-.435.526-1.229-.135-.793-.661a4.818 4.818 0 017.403.003c.439.523-.355 1.184-.79.658z'/%3E%3C/svg%3E"),
    // meh
    '0.5':
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zM5.42 5.42a1.031 1.031 0 110 2.064 1.031 1.031 0 110-2.065zm5.677 6.193H4.903c-.684 0-.684-1.032 0-1.032h6.194c.684 0 .684 1.032 0 1.032zm-.516-4.13a1.031 1.031 0 110-2.064 1.031 1.031 0 110 2.065z'/%3E%3C/svg%3E"),
    // smile
    '1':
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm2.58 5.42a1.031 1.031 0 110 2.064 1.031 1.031 0 110-2.065zm-5.16 0a1.031 1.031 0 110 2.064 1.031 1.031 0 110-2.065zm6.283 5.49A4.806 4.806 0 018 12.645a4.806 4.806 0 01-3.703-1.735c-.439-.526.355-1.184.793-.662A3.77 3.77 0 008 11.61a3.78 3.78 0 002.91-1.362c.432-.522 1.229.136.793.662z'/%3E%3C/svg%3E"),
    // laugh
    '2':
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm2.58 4.903a1.031 1.031 0 110 2.065 1.031 1.031 0 110-2.065zm-5.16 0a1.031 1.031 0 110 2.065 1.031 1.031 0 110-2.065zm2.838 8.774h-.516a4.65 4.65 0 01-4.61-4.064.518.518 0 01.513-.58h8.71c.31 0 .551.27.513.58a4.65 4.65 0 01-4.61 4.064z'/%3E%3C/svg%3E")
  );

  @each $score in $scores {
    [data-score='#{$score}'] {
      // color: map-get($score-colors, $score);
      text-decoration: map-get($score-decoration, $score);

      &[type='radio'] {
        + label {
          border-color: map-get($score-colors, $score) !important;
          // text-decoration: map-get($score-decoration, $score);

          &:after {
            background-image: map-get($score-icons, $score) !important;
            background-color: map-get($score-colors, $score) !important;
          }
        }
      }
      &[type='checkbox'] {
        + label {
          border-color: map-get($score-colors, $score) !important;
          // text-decoration: map-get($score-decoration, $score);

          &:after {
            background-image: map-get($score-icons, $score) !important;
            background-color: map-get($score-colors, $score) !important;
            border-radius: 0.1825em;
          }
        }
      }
    }
  }
}
