@import '../_shared/imports';

.list__team {
  margin-bottom: -2em;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  list-style-type: none;

  @include below('phone') {
    font-size: 0.875em;
  }

  &-item {
    position: relative;

    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 2em;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;

    border: 0;
    border-radius: 0.25em;

    @include above('laptop') {
      flex-basis: 48%;
      flex-basis: calc(50% - 1.5em);
      max-width: 48%;
      max-width: calc(50% - 1.5em);
    }

    .card-img-top {
      position: relative;
      z-index: 3;

      width: 11.625em;
      height: 11.625em;
      margin-bottom: -3.375em;

      align-self: center;

      border-radius: 50%;
      border: 0.1875em solid $primary;

      @include above('phone') {
        + .card-body {
          padding-top: 4.2em;
        }
      }

      @include below('phone') {
        position: absolute;
        top: -0.375em;
        left: 0.5em;

        width: 5em;
        height: 5em;

        + .card-body {
          .card-title,
          .card-subtitle {
            padding-left: 4.5rem;
          }
        }
      }
    }

    .toggle {
      &:after {
        @include above('phone') {
          display: none !important;
        }

        cursor: pointer;
        content: '';
        position: absolute;
        top: 1em;
        right: 1em;
        z-index: 5;

        display: flex;
        justify-content: center;
        align-items: flex-end;

        border: 0.5em solid transparent;
        border-top-color: $primary;
        border-bottom-width: 0;

        transition: transform 0.2s;
      }

      &.toggled {
        &:after {
          transform: rotateX(180deg);
        }
      }

      .card-title,
      .card-subtitle {
        padding-right: 1.5rem;
      }
    }

    .card-body,
    .card-footer {
      background-color: $light;
      border: 0;

      text-align: center;
    }

    .card-body {
      position: relative;
      @include below('phone') {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        text-align: left;
      }
      + .card-body {
        padding-top: 0;
      }
    }

    .card-title {
      color: $primary;

      font-size: 1.5em;
    }

    .card-subtitle {
      color: $gray-600;

      font-size: 0.875em;
      font-weight: normal;
    }

    .card-footer {
      a {
        position: relative;
        margin: 0 0.25em;
        padding: 0.25em 0;
        display: inline-block;

        .info {
          position: absolute;
          top: 100%;
          left: 50%;

          padding: 0.125em 0.75em;

          color: $white;
          background-color: $primary;
          border-radius: 1em;
          opacity: 0;

          white-space: nowrap;

          transition: opacity 0.2s;
          transform: translateX(-50%);
        }

        &:hover,
        &:focus {
          z-index: 2;

          .info {
            opacity: 1;
          }
        }
      }
    }
  }
}
