@import '../_shared/imports';
@import 'amplify_nav';

#nav {
  padding: 2em 0;

  color: $white;
  color: color-yiq($primary);
  background-color: $primary;
  background-color: var(--primary, $primary);

  font-family: $headings-font-family;

  @include below('tablet') {
    padding: 1em 0;
  }

  &-body {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  a {
    color: inherit;
  }

  .nav {
    margin-left: auto;
    margin-right: -0.25em;
    margin-top: 1em;

    justify-content: flex-end;
  }

  .nav-item,
  .admin-dashboard__tools .nav-item {
    margin: 0 0.25em;
    padding: 0 0.25em;

    font-size: 1.5em;

    .dropdown-toggle {
      padding: 0; // fix default bootstrap layout
    }

    .dropdown-menu {
      font-size: 1rem;
      text-align: center;
    }
  }

  .navbar-toggler {
    position: relative;

    width: 1.75rem;
    height: 1.25rem;
    margin-left: auto;
    padding: 0;

    align-self: center;

    background-color: white;
    border: 0;
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    background-clip: padding-box;
    outline: 0;

    transition: background-color 0.2s 0.2s;

    > * {
      display: none;
    }

    &::before,
    &::after {
      content: '';

      position: absolute;
      left: 0;
      right: 0;

      height: 0.25rem;

      background-color: white;

      transition: top 0.2s 0.2s, bottom 0.2s 0.2s, transform 0.2s 0s;
    }

    &:before {
      top: -0.5rem;
    }

    &:after {
      bottom: -0.5rem;
    }

    &__open {
      background-color: transparent;

      transition: background-color 0.2s 0;

      &:before,
      &:after {
        transition: top 0.2s, bottom 0.2s, transform 0.2s 0.2s;
      }

      &:before {
        top: 0;
        transform: rotate(45deg);
      }

      &:after {
        bottom: 0;
        transform: rotate(-45deg);
      }
    }
  }
}
