@import '../_shared/imports';

.bg {
  position: relative;

  &:before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    pointer-events: none;
  }

  &[style] {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &:before {
      opacity: 0.85;
    }
  }

  @each $colorName, $color in $theme-colors {
    &.bg-#{$colorName} {
      &:before {
        z-index: -1;
        background-color: $color;
        border-radius: 0.25em;
      }
    }
  }
}
