@import '../_shared/imports';

$width__s: map-get($spacers, 3);
$width__m: map-get($spacers, 4);
$width__l: map-get($spacers, 5);

.width {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: $width__l + $width__m;
  padding-right: $width__l + $width__m;

  @include below('lg') {
    padding-left: $width__l;
    padding-right: $width__l;
  }

  @include below('md') {
    padding-left: $width__m;
    padding-right: $width__m;
  }

  @include below('sm') {
    padding-left: $width__s;
    padding-right: $width__s;
  }

  @each $widthName, $width in $grid-breakpoints {
    &.width__#{$widthName} {
      max-width: $width;
    }
  }

  &.no-width {
    max-width: 100%;
  }

  &.no-space {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &.width__vertical {
    padding-top: ($width__l + $width__m) * 0.67;
    padding-bottom: ($width__l + $width__m) * 0.67;

    @include below('lg') {
      padding-top: $width__l * 0.67;
      padding-bottom: $width__l * 0.67;
    }

    @include below('md') {
      padding-top: $width__m * 0.67;
      padding-bottom: $width__m * 0.67;
    }

    @include below('sm') {
      padding-top: $width__s * 0.67;
      padding-bottom: $width__s * 0.67;
    }
  }

  > :last-child {
    margin-bottom: 0 !important;
  }

  > .width:not(.width__force) {
    padding-left: 0;
    padding-right: 0;
  }

  > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
