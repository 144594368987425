@import '../_shared/imports';

.contentbox {
  padding: 1.5em;

  border: 1px solid $primary;
  border: 1px solid var(--primary, $primary);
  background-color: $light;
  background-color: var(--light, $light);
  border-radius: 0.25em;

  .btn {
    max-height: 2.5em;

    color: $light !important;
    color: var(--light, $light) !important;
    background-color: $primary;
    background-color: var(--primary, $primary);

    &:hover {
      color: $primary !important;
      color: var(--primary, $primary) !important;
      background-color: $gray-200;
    }
  }
}
